import { Box, Grid, Typography, Container } from '@mui/material';
import '../Styles.css';

function Contact() {
  return (
    <Container
      maxWidth="md"
      sx={{
        marginTop: '1rem',
        padding: '1rem',
        borderRadius: '8px',
        boxShadow: 3,
        minHeight: '70vh', 
        margin: 'auto',
        color: 'white'
      }}
    >
      <Typography variant="h4" align="center" >
        İletişim
      </Typography>

      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Adres:
          </Typography>
          <Typography variant="body1" className="address">
            Remzi Oğuz Arık, Bülten Sk. No:9/1<br />
            06680 Çankaya/Ankara<br />
            Tel: <strong><a href="tel:05439535833" style={{ color: 'inherit', textDecoration: 'none' }}>0543 953 58 33</a></strong>
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: '1rem', height: '400px', overflow: 'hidden' }}>
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.5273079968642!2d32.858074175711685!3d39.90721428649313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34fa4409bbeed%3A0xf84dde8e1bc94060!2zUmVtemkgT8SfdXogQXLEsWssIELDvGx0ZW4gU2suIE5vOjkgRDoxLCAwNjY4MCDDh2Fua2F5YS9BbmthcmE!5e0!3m2!1str!2str!4v1729235428998!5m2!1str!2str"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </Box>
    </Container>
  );
}

export default Contact;
