import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Link, Box, IconButton, Drawer } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = (
    <Box sx={{ display: { xs: 'block', md: 'flex' }, gap: '1rem', justifyContent: 'flex-end', width: '100%' }}>
      <Link component={RouterLink} to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Typography variant="body1">Anasayfa</Typography>
      </Link>
      <Link component={RouterLink} to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Typography variant="body1">Hakkımızda</Typography>
      </Link>
      <Link component={RouterLink} to="/workspaces" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Typography variant="body1">Çalışma Alanlarımız</Typography>
      </Link>
      <Link component={RouterLink} to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Typography variant="body1">İletişim</Typography>
      </Link>
    </Box>
  );

  return (
    <AppBar
      position="absolute"
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'inherit' }}></Typography>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', flexGrow: 1 }}>
          {menuItems}
        </Box>
        <IconButton 
          edge="end" 
          color="inherit" 
          onClick={toggleDrawer} 
          sx={{ display: { xs: 'flex', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
          >
            {menuItems}
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
