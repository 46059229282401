import React from 'react';
import '../Styles.css'; 

function About() {
    return (
      <div className="about">
        <h2>Hakkımızda</h2>
        <p>
          Hazırlanıyor...
        </p>
      </div>
    );
  }
  
  export default About;
  