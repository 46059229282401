import React from 'react';
import '../Styles.css';

function Footer() {
  return (
    <div className="footer">
      <p>© 2024 Dorukcan Davutoğlu. Tüm hakları saklıdır.</p>
    </div>
  );
}

export default Footer;
