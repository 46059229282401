import React from 'react';
import '../Styles.css'; 

function Workspaces() {
    return (
      <div className="workspaces">
        <h2>Çalışma Alanlarımız</h2>
        <ul>
          <li>Aile Hukuku</li>
          <li>Ceza Hukuku</li>
          <li>Ticaret Hukuku</li>
          <li>İş Hukuku</li>
          <li>Sözleşmeler Hukuku</li>
          <li>Gayrimenkul Hukuku</li>
          <li>Miras Hukuku</li>
          <li>İdare Hukuku</li>
          <li>Sigorta Hukuku</li>
        </ul>
      </div>
    );
}

export default Workspaces;
