import React from 'react';
import '../Styles.css'; 

const Home = () => {
  return (
    <div className="homepage">
           
    </div>
  );
};

export default Home;
